import React, { useRef, useEffect, useState } from 'react';

import './App.css';
import Nav from "../Nav/Nav";
import Header from "../Header/Header";
import AboutUs from "../AboutUs/AboutUs";
import Service from "../Service/Service";
import Work from "../Work/Work";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Preloader from "../Preloader/Preloader";
import CustomCursor from "../CustomCursor";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import metaIMG from '../../images/triick_meta_tag_image.png';
import metaIMG_case_study_wwa from '../../images/triick_meta_tag_image_case_study_wwa.png';
import whatsapp_avater from '../../images/whatsapp_avater.png';
import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Route, Routes, Navigate } from 'react-router-dom';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import ReactPixel from 'react-facebook-pixel';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { MessengerChat } from "react-messenger-chat-plugin";
import WWApp from '../CaseStudy/WWApp/WWApp';
gsap.registerPlugin(ScrollSmoother, ScrollTrigger, ScrollToPlugin);
function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function App() {
    const mobileWindoeSize = 1101;
    const containerRef = useRef(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const [invert, setInvert] = useState(false);
    const [loader, setLoader] = useState(true);
    const breadCrumb = document.querySelector(".active_breadcrumb");
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    let smoother;

    ReactPixel.init('832076754624945');
    ReactPixel.pageView();

    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    })

    const handleInvert = (value) => {
        setInvert(value);
    };

    // Scroll triggers for Breadcrumb
    ScrollTrigger.batch(".breadcrumb_trigger", {
        start: "top-=200",
        end: "bottom-=200",
        onEnter: (self) =>
            (breadCrumb.innerText = self[0].dataset.breadcrumbtext),
        onEnterBack: (self) =>
            (breadCrumb.innerText = self[0].dataset.breadcrumbtext),
    });

    useEffect(() => {
        // Remove Preloader
        setTimeout(() => {
            setLoader(false);
        }, 3000);

        if (dimensions.width < mobileWindoeSize) {
            setIsMobileView(true);
            // return;
        };


        // Creating scrollSmoother
        smoother = ScrollSmoother.create({
            content: containerRef.current,
            smooth: 2,
            effects: true,
            smoothTouch: false,
        });


        if (window.location.hash) {
            const timer = setTimeout(() => {
                gsap.to(smoother, {
                    scrollTop: smoother.offset(window.location.hash, "top 100px"),
                    duration: 2
                });
                // remove hash link
                window.history.replaceState(null, null, ' ');
            }, 4000);
            return () => clearTimeout(timer);
        };





    }, []);

    // Invert trigger
    useEffect(() => {
        const app = document.querySelector('html');
        invert ? app.classList.add("invert") : app.classList.remove("invert");
    }, [invert]);



    useEffect(() => {
        if (dimensions.width < mobileWindoeSize) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        };
        // 
    }, [dimensions.width]);


    return (
        <HelmetProvider>
            <Helmet>

                <title>Triick | Our digital products and services make your business grow</title>
                <meta name="keywords" content="App,Web,Develop,Design,Application,Website,iOS,Google,Windows, HTML,CSS,XML,JavaScript, United Kingdom," />
                <meta name="title" content="Triick | Our digital products and services make your business grow" />
                <meta name="description" content="Triick is a UK-based mobile app and website agency that designs and develops businesses. Your tech partner to generate ideas, build products and accelerate growth." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.triick.co.uk/" />
                <meta property="og:title" content="Triick | Our digital products and services make your business grow" />
                <meta property="og:description" content="Triick is a UK-based mobile app and website agency that designs and develops businesses. Your tech partner to generate ideas, build products and accelerate growth." />
                <meta property="og:image" content={metaIMG} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.triick.co.uk/" />
                <meta property="twitter:title" content="Triick | Our digital products and services make your business grow" />
                <meta property="twitter:description" content="Triick is a UK-based mobile app and website agency that designs and develops businesses. Your tech partner to generate ideas, build products and accelerate growth." />
                <meta property="twitter:image" content={metaIMG} />

            </Helmet>

            {/* {loader && <Preloader />} */}
            {loader && <Preloader />}
            <Routes>
                <Route path="/" element={
                    <>
                        {(!isMobileView) ? <CustomCursor /> : null}
                        <Nav handleInvert={handleInvert} isMobileView={isMobileView} nav_active={false} />
                        <div className="wrapper" id="wrapper" ref={containerRef} >
                            <Header />
                            <AboutUs isMobileView={isMobileView} loader={loader} containerRef={containerRef} smoother={smoother} />
                            <Service />
                            <Work />
                            <Contact />
                            <Footer />
                        </div>
                        {
                            isMobileView ?
                                <FloatingWhatsApp
                                    phoneNumber="447838106661"
                                    accountName="TRI/*CK"
                                    statusMessage="Typically replies within few minutes"
                                    // chatMessage="Hi, there. \n How can we help you?"
                                    // chatMessage="Hello there! 🤝 \n How can we help?"
                                    avatar={whatsapp_avater}
                                    // placeholder="How are you?"
                                    // chatMessage2=""
                                    allowEsc
                                    allowClickAway
                                    notification
                                    notificationSound
                                />
                                :

                                <MessengerChat pageId="105473071290630" greetingDialogDisplay="welcome_message" welcome_screen_greeting="Hello there! 🤝 \n How can we help?" />
                        }

                    </>
                } />
                <Route exact path="/WWApp" element={
                    <>
                        <Helmet>
                            <title>Triick | Case Study - Window Warehouse App</title>
                            <meta name="keywords" content="App,Web,Develop,Design,Application,Website,iOS,Google,Windows, HTML,CSS,XML,JavaScript, United Kingdom," />
                            <meta name="title" content="Triick | Case Study - Window Warehouse App" />
                            <meta name="description" content="The Window Warehouse app is designed to help streamline customer service and is the ultimate resource for all your window and door needs." />

                            <meta property="og:type" content="website" />
                            <meta property="og:url" content="https://www.triick.co.uk/wwapp" />
                            <meta property="og:title" content="Triick | Case Study - Window Warehouse App" />
                            <meta property="og:description" content="The Window Warehouse app is designed to help streamline customer service and is the ultimate resource for all your window and door needs." />
                            <meta property="og:image" content={metaIMG_case_study_wwa} />

                            <meta property="twitter:card" content="summary_large_image" />
                            <meta property="twitter:url" content="https://www.triick.co.uk/wwapp" />
                            <meta property="twitter:title" content="Triick | Case Study - Window Warehouse App" />
                            <meta property="twitter:description" content="The Window Warehouse app is designed to help streamline customer service and is the ultimate resource for all your window and door needs." />
                            <meta property="twitter:image" content={metaIMG_case_study_wwa} />
                        </Helmet>
                        {(!isMobileView) ? <CustomCursor /> : null}
                        <Nav handleInvert={handleInvert} isMobileView={isMobileView} nav_active={true} />
                        <div className="wrapper" id="wrapper" ref={containerRef} >
                            <WWApp loader={loader} isMobileView={isMobileView} />
                            <Footer />
                        </div>
                        {
                            isMobileView ?
                                <FloatingWhatsApp
                                    phoneNumber="447838106661"
                                    accountName="TRI/*CK"
                                    statusMessage="Typically replies within few minutes"
                                    // chatMessage="Hi, there. \n How can we help you?"
                                    // chatMessage="Hello there! 🤝 \n How can we help?"
                                    avatar={whatsapp_avater}
                                    // placeholder="How are you?"
                                    // chatMessage2=""
                                    allowEsc
                                    allowClickAway
                                    notification
                                    notificationSound
                                />
                                :

                                <MessengerChat pageId="105473071290630" greetingDialogDisplay="welcome_message" welcome_screen_greeting="Hello there! 🤝 \n How can we help?" />
                        }

                    </>
                } />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/*' element={<Navigate to="/" replace={true} />} />
            </Routes>

        </HelmetProvider>

    );
}

export default App;