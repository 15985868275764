import "./Preloader.css";

function Preloader() {

    return (
        <div className="preloader">
            <div className="preloader_container">
                <svg className="left_bracket" height="100%" viewBox="0 0 334.92 970.04" xmlns="http://www.w3.org/2000/svg">
                    <path d="M230.3,355.8c-1.1,75-16.5,106.3-61.7,129.8c44.1,24.6,61.7,60.4,61.7,132v149.9c0,80.6,20.9,101.8,104.7,101.8
                            v99.6c-9.9,1.1-18.7,1.1-22,1.1c-99.1,0-130-6.7-158.6-34.7c-27.5-28-38.5-66-38.5-130.9V633.3c0-63.8-16.5-90.6-62.8-104.1v-91.7
                            c46.3-13.4,61.7-39.2,62.8-99.6V153.3c0-58.2,12.1-95.1,39.7-119.7C185.2,6.7,218.2,0,313,0c3.3,0,12.1,0,22,1.1v105.2
                            c-82.6,1.1-105.8,22.4-104.7,98.4V355.8z" />
                </svg>

                <div className="main_logo" >
                    <svg className="logo_text" viewBox="0 0 3901.57 760.72" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path d="M349.1,675.9H202.6V202H0V81.7h550.8V202H349.1V675.9z" />
                            <path d="M774.5,675.9H628V81.7h381.6c67.5,0,105.2,8,139.5,29.4c43.9,27.6,71.1,78.4,71.1,133.6
                                    c0,70.4-35.1,122.9-101.8,150.6c57.9,21.4,75.4,54.3,77.2,147c2.6,74,7,95.3,24.6,133.6h-161.4c-10.5-33-12.3-46.3-11.4-103.3
                                    c0.9-86.4-21.9-112.2-98.2-113.1H774.5V675.9z M969.2,339.2c32.5,0,45.6-1.8,58.8-8.9c20.2-10.7,34.2-34.7,34.2-59.7
                                    c0-24.9-14-49-34.2-59.7c-13.2-7.1-26.3-8.9-58.8-8.9H774.5v137.2H969.2z" />
                            <path d="M1345.5,675.9V81.7H1492v594.2H1345.5z" />
                            <path d="M2032.3,81.7l-325.4,594.2h-142.1l325.4-594.2H2032.3z" />
                            <path d="M2292,223.4l-5.3,95.3l87.7-34.7l29.8,94.4l-91.2,24.1l59.6,73.9l-78.9,57.9l-50.9-80.2l-50,80.2l-78.9-57.9
		                            l59.6-73.9l-91.2-24.9l29.8-93.5l87.7,34.7l-5.3-95.3H2292z" />
                            <path d="M3173.5,443c-42.1,170.1-156.1,253.9-344.7,253.9c-107,0-191.2-26.7-252.6-81.1
                                    c-64.9-56.1-99.1-137.2-99.1-231.6c0-196,140.3-323.4,355.2-323.4c102.6,0,183.3,27.6,246.5,85.5c46.5,41.9,72.8,84.6,86,142.5
                                    h-157.9c-29.8-74.8-87.7-112.2-174.5-112.2c-117.5,0-200,84.6-200,204.9c0,117.6,80.7,199.6,196.5,199.6
                                    c93.9,0,149.1-41.9,183.3-138.1H3173.5z" />
                            <path d="M3901.6,675.9h-194.7l-203.5-257.5l-78.1,68.6v188.9h-146.5V81.7h146.5v233.4l257.9-233.4h206.1l-275.4,241.4
		                            L3901.6,675.9z" />
                        </g>
                    </svg>
                </div>

                <svg className="right_bracket" height="100%" viewBox="0 0 334.92 970.04" xmlns="http://www.w3.org/2000/svg">
                    <path d="M157.8,204.7c1.1-76-22.1-97.3-104.7-98.4V1.1C63,0,71.8,0,75.1,0c94.8,0,127.8,6.7,157.4,33.6
                            c27.6,24.6,39.7,61.5,39.7,119.7v184.6c1.1,60.4,16.5,86.2,62.8,99.6v91.7c-46.3,13.5-62.8,40.3-62.8,104.1v171.1
                            c0,64.9-11,102.9-38.5,130.9c-28.6,28-59.5,34.7-158.6,34.7c-3.3,0-12.1,0-22-1.1v-99.6c83.8,0,104.7-21.2,104.7-101.8V617.6
                            c0-71.6,17.6-107.4,61.7-132c-45.2-23.5-60.6-54.8-61.7-129.8V204.7z" />
                </svg>
            </div>
        </div>
    )
}

export default Preloader;