import "./WWApp.css";
import case_study_header_img from "../../../images/case_study_header_img.png";
import challenges_wwapp from "../../../images/challenges_wwapp.png";
import solution_wwapp from "../../../images/solution_wwapp.png";
import results_wwapp from "../../../images/results_wwapp.png";
import playstore_icon from "../../../images/playstore_icon.svg";
import appstore_icon from "../../../images/appstore_icon.svg";
import figma_logo from "../../../images/stack_icons/figma_logo.svg";
import ai_logo from "../../../images/stack_icons/ai_logo.svg";
import reactnative_logo from "../../../images/stack_icons/reactnative_logo.svg";
import expo_logo from "../../../images/stack_icons/expo_logo.svg";
import nodejs_logo from "../../../images/stack_icons/nodejs_logo.svg";
import googlecloud_logo from "../../../images/stack_icons/googlecloud_logo.svg";
import firebase_logo from "../../../images/stack_icons/firebase_logo.svg";
import expressjs_logo from "../../../images/stack_icons/expressjs_logo.svg";

import gsap from "gsap";
import { Observer } from "gsap/Observer";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useEffect } from "react";
gsap.registerPlugin(ScrollSmoother, ScrollTrigger, ScrollToPlugin, Observer);

function WWApp({ loader, isMobileView }) {

    const domainName = window.location.origin;
    function scrollToSection(e, target) {
        e.preventDefault();
        gsap.to(window, { delay: 0, duration: 1, scrollTo: { y: target } });
    };

    useEffect(() => {


        if (loader) return;

        // =====================

        if (isMobileView) return;
        var points = gsap.utils.toArray('.fixed_content');
        var height = 70 * points.length;

        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".fixed_content_wrapper",
                start: "top bottom",
                end: "+=" + height + "%",
                scrub: true,
                id: "points",
                snap: "labelsDirectional"
            }
        })

        ScrollTrigger.create({
            trigger: ".fixed_content_wrapper .wrapper",
            start: "top top",
            end: "+=" + height + "%",
            scrub: true,
            pin: ".fixed_content_wrapper .wrapper",
            pinSpacing: true,
            id: "pinning",
            // markers: true
        })



        points.forEach(function (elem, i) {
            gsap.set(elem, { position: "absolute", top: 0 });
            if (i == 0) {
                tl.from(elem.querySelector('.case_study_image_container'), { autoAlpha: 1 }, i)
                tl.from(elem.querySelector('.case_study_text_content'), { autoAlpha: 1, translateY: 0 }, i)
            } else {
                tl.from(elem.querySelector('.case_study_image_container'), { autoAlpha: 0 }, i)
                tl.from(elem.querySelector('.case_study_text_content'), { autoAlpha: 0, translateY: 100 }, i)
                tl.from(elem.querySelector('.tech_stack'), { autoAlpha: 0, translateY: 100 }, i)
            }
            tl.add("label" + i);

            if (i != points.length - 1) {
                tl.to(elem.querySelector('.case_study_text_content'), { autoAlpha: 0, translateY: -100 }, i + 0.75)
                tl.to(elem.querySelector('.case_study_image_container'), { autoAlpha: 0 }, i + 0.75)
                tl.to(elem.querySelector('.tech_stack'), { autoAlpha: 0, translateY: -100 }, i + 0.75)
            }

        });

    }, [loader])


    return (
        <section id="case_study" className="case_study">
            <div className="container">
                <div className="case_study_content_wrap breadcrumb_trigger" id="section-1" data-breadcrumbtext="CASE STUDY">
                    <div className="case_study_container">
                        <div className="case_study_text_content">
                            <p className="case_study_small_text">Case Study</p>
                            <h1 className="heading_1">Window Warehouse App</h1>
                            <p className="paragraph_1">
                                The Window Warehouse app is designed to help streamline customer service and is the ultimate resource for all your window and door needs.
                            </p>
                            <a href="#" className="big_link_text" onClick={(e) => scrollToSection(e, "#section-2")}>Read more...</a>
                            <ul className="details_table">
                                <li>
                                    <p className="details_table_text"><span className="details_table_text">Industry</span>Construction</p>
                                </li>
                                <li>
                                    <p className="details_table_text"><span className="details_table_text">App Type</span>Customer Service</p>
                                </li>
                                <li>
                                    <p className="details_table_text"><span className="details_table_text">Platforms</span>ios & Android</p>
                                </li>
                                <li>
                                    <p className="details_table_text"><span className="details_table_text">Services provided</span>Design & Development</p>
                                </li>
                            </ul>
                            <div className="store_icons no_invert">
                                <a href="https://apps.apple.com/gb/app/window-warehouse/id6448803629" target="_blank" className="store_icon">
                                    <img src={appstore_icon} alt="appstore_icon" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=uk.co.triick.windowwarehouse&pli=1" target="_blank" className="store_icon">
                                    <img src={playstore_icon} alt="playstore_icon" />
                                </a>
                            </div>
                        </div>
                        <div className="case_study_image_container">
                            <img src={case_study_header_img} alt="case_study_header_img" className="case_study_header_img no_invert" />
                        </div>
                    </div>
                </div>
                <div className="fixed_content_wrapper " id="section-2">
                    <div className="wrapper">
                        <div className="breadcrumb_trigger fixed_content" data-breadcrumbtext="CASE STUDY // CHALLENGE">
                            <p className="case_study_small_text">Case Study</p>
                            <h1 className="heading_1">Window Warehouse App</h1>
                            <div className="case_study_container">
                                <div className="case_study_text_content">
                                    <h1 className="heading_2">Challenge</h1>
                                    <p className="paragraph_2">
                                        When it comes to high-quality uPVC and aluminium windows and doors for homes and businesses, Window Warehouse is now the undisputed leader on the south coast. Customer service has always been a top priority for the company, even though a lot has changed over the company's 35 years in business.
                                        <br />
                                        <br />
                                        Before working with TRI/*CK, Window Warehouse wanted to improve its customer service processes, particularly those involving the reporting of faulty products. They also desired an app that would provide instantaneous access to all relevant data.
                                        <br />
                                        <br />
                                        Without access to an API for its existing system, Window Warehouse realised that QR codes could be generated for its products containing all the required data for a fault report. The task for TRI/*CK was to create a mobile application that could scan and combine this data with attachments and contact information before being submitted via email to the customer services department.
                                    </p>
                                </div>
                                <div className="case_study_image_container">
                                    <img src={challenges_wwapp} alt="challenges_wwapp_img" className="challenges_wwapp_img no_invert" />
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb_trigger fixed_content" data-breadcrumbtext="CASE STUDY // OUR SOLUTION">
                            <p className="case_study_small_text">Case Study</p>
                            <h1 className="heading_1">Window Warehouse App</h1>
                            <div className="case_study_container">
                                <div className="case_study_text_content">
                                    <h1 className="heading_2">Our Solution</h1>
                                    <p className="paragraph_2">
                                        We used insights from our intensive design and development thinking sessions to identify the best practises for displaying data from a QR code on screen in a clear and accessible format.
                                        <br />
                                        <br />
                                        Using in-device technology, we instantly decode QR code information and display the translated text. Customers can then take photos or select pre-existing images from the gallery to supplement the scanned information. After adding photographic evidence, the user can enter additional information and descriptions using the available sections.
                                        <br />
                                        <br />
                                        Once all data has been entered, the user can submit the report, which emails customer service with all relevant information and imagery. Giving the user a flawless experience that is also simple to understand at customer service.
                                    </p>
                                </div>
                                <div className="case_study_image_container">
                                    <img src={solution_wwapp} alt="challenges_wwapp_img" className="challenges_wwapp_img no_invert" />
                                </div>
                            </div>
                            <div className="tech_stack">
                                <p className="case_study_small_text">Technology Stack</p>
                                <ul className="stack_icons">
                                    <li>
                                        <img src={figma_logo} alt="figma_logo" />
                                    </li>
                                    <li>
                                        <img src={ai_logo} alt="ai_logo" />
                                    </li>
                                    <li>
                                        <img src={reactnative_logo} alt="reactnative_logo" />
                                    </li>
                                    <li>
                                        <img src={expo_logo} alt="expo_logo" />
                                    </li>
                                    <li>
                                        <img src={nodejs_logo} alt="nodejs_logo" />
                                    </li>
                                    <li>
                                        <img src={googlecloud_logo} alt="googlecloud_logo" />
                                    </li>
                                    <li>
                                        <img src={firebase_logo} alt="firebase_logo" />
                                    </li>
                                    <li>
                                        <img src={expressjs_logo} alt="expressjs_logo" />
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="breadcrumb_trigger fixed_content" data-breadcrumbtext="CASE STUDY // RESULT">
                            <p className="case_study_small_text">Case Study</p>
                            <h1 className="heading_1">Window Warehouse App</h1>
                            <div className="case_study_container">
                                <div className="case_study_text_content">
                                    <h1 className="heading_2">Result</h1>
                                    <p className="paragraph_2">
                                        We exceeded Window Warehouse's expectations with our industry expertise, outstanding team, and refined agile development and design thinking processes.
                                        <br />
                                        <br />
                                        The app was launched in five months, meeting timeline restrictions, and has enabled Window Warehouse to offer their customers a better service and put them in a position to compete with the most prominent competitors in their industry nationally.

                                    </p>
                                    <p className="key_features_title">
                                        Key features of the app include:
                                    </p>
                                    <ul>
                                        <li>
                                            <p className="paragraph_2">Report a fault direct to customer service including visual evidence.</p>
                                        </li>
                                        <li>
                                            <p className="paragraph_2">Quick navigation to important information.</p>
                                        </li>
                                        <li>
                                            <p className="paragraph_2">Gallery to enhance sales.</p>
                                        </li>
                                        <li>
                                            <p className="paragraph_2">Latest industry news.</p>
                                        </li>
                                    </ul>
                                    <a href={`${domainName}#contact`} className="heading_2 get_in_touch_link">Get in touch with us today to talk about your app idea!</a>
                                </div>
                                <div className="case_study_image_container">
                                    <img src={results_wwapp} alt="challenges_wwapp_img" className="challenges_wwapp_img no_invert" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WWApp;