import { useEffect, useRef, useState } from "react";
import "./Contact.css";
import SendMail from "./helper/Mail";
import gsap from "gsap";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(SplitText);

function Contact() {
    const nameRef = useRef();
    const addressRef = useRef();
    const aboutlRef = useRef();
    const emailRef = useRef();
    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const closeModal = () => {
        setIsSending(false);
        setIsError(false);
        setIsSuccess(false);
        nameRef.current.value = '';
        addressRef.current.value = '';
        aboutlRef.current.value = '';
        emailRef.current.value = '';
    }
    function handleSubmit(e) {
        e.preventDefault();
        const name = nameRef.current.value;
        const address = addressRef.current.value;
        const about = aboutlRef.current.value;
        const email = emailRef.current.value;

        console.log(name, address, about, email);

        setIsSending(true);


        SendMail({ name, address, about, email }).then(res => {
            if (res) {
                console.log("Success", res);
                setIsSuccess(true);
            } else {
                console.log("Error", res);
                setIsError(true);
                // setStatus(true);
            }
        }).catch(() => {
            console.log("error in sending mail");
            setIsError(true);
        });
    }

    useEffect(() => {

        if (!isSending) return;

        const sendingWords = new SplitText(".status_modal .email_status", { type: "words,chars" });

        gsap.from(sendingWords.chars, {
            y: 40,
            opacity: 0,
            stagger: 0.08,
            ease: "back.out(1.7)",
        });

    }, [isSending, isSuccess, isError]);

    return (
        <section data-scroll-section id="contact" className="contact breadcrumb_trigger" data-breadcrumbtext="Let's talk" data-idname="contact">
            <div className="container">
                <div className="contact_content_wrap">
                    <h1 className="heading_1">Let’s Talk</h1>
                    <form className={`contact_form ` + (isSending ? 'sending' : '')} id="contact_form" onSubmit={handleSubmit}>
                        <span>
                            What's up, I'm <input ref={nameRef} type="text" maxLength={30} name="name" className="form_input" required /> from
                            <input ref={addressRef} type="text" name="address" maxLength={30} className="form_input" required />.
                            I'd love to have a discussion with Triick about my
                            <input ref={aboutlRef} type="text" name="about" maxLength={30} className="form_input" required />.
                            My email address is <input ref={emailRef} type="email" maxLength={30} className="form_input" name="email" required />.
                        </span>

                        <br />
                        <button type="submit" name="submit" className="from_submit">SUBMIT {">>"}</button>

                        {
                            isSending &&
                            <div className="status_modal">

                                <div className="status_modal_content">
                                    {
                                        isSuccess && <p className="email_status success">Sent Successfully.</p>
                                    }
                                    {
                                        isError && <p className="email_status error">Something Went wrong.</p>
                                    }
                                    {
                                        (!isSuccess && !isError) && <p className="email_status">Sending...</p>
                                    }
                                </div>

                                {
                                    (isSuccess || isError) && <p className="modal_close" onClick={closeModal}>CLOSE {"X"}</p>
                                }
                            </div>
                        }

                    </form>
                </div>
            </div>

        </section>
    );
}

export default Contact;