import "./AboutUs.css";
import React, { useEffect, useRef, useState } from "react";
import useOnScreen from "../useOnScreen";
import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollSmoother, ScrollTrigger, ScrollToPlugin);

const aboutItems = [
    {
        title: "Who We Are",
        subtitle: "A group of talented designers and programmers working to produce cutting-edge design & web applications. The technology partner that enhances and accelerates your capabilities to produce expert digital products.",
    },
    {
        title: "What We Do",
        subtitle: "We create and deliver digital products that launch businesses to the top of their industry, and we're proud to say that we've been working with many of our clients for many years, supporting and serving them through each new phase of their business.",
    },
    {
        title: "How We Do It",
        subtitle: "We set you up with your dream team that combines best-in-class product strategy, design and development to design a tailored approach that is right for your company financially, strategically, and technologically to give you the competitive advantage you need.",
    }
];

function AboutItem({
    subtitle,
    title,
    updateItem,
    index,
}) {
    const ref = useRef(null);

    const onScreen = useOnScreen(ref, 0.5);

    useEffect(() => {
        if (onScreen) {
            updateItem(index);
        }
    }, [onScreen, index]);

    return (
        <div className="aboutItem" ref={ref}>
            <div className="container">
                <div className="about_us_content_wrap">
                    <h1 className="heading_1">{title}</h1>
                    <p className="paragraph_1">
                        {subtitle}
                    </p>
                </div>
            </div>
        </div>
    );
}

const AboutUs = ({ loader, isMobileView, containerRef, smoother }) => {
    const [breadCrumbText, setBreadCrumbText] = useState('');
    const ref = useRef(null);
    const horizontalRef = useRef(null);

    const handleUpdateItem = (index) => {
        const breadCrumb = document.querySelector(".active_breadcrumb");
        if (index === 0) {
            setBreadCrumbText("About Us // Who We Are");
            breadCrumb.innerText = "About Us // Who We Are";
        } else if (index === 1) {
            setBreadCrumbText("About Us // What We Do");
            breadCrumb.innerText = "About Us // What We Do";
        } else if (index === 2) {
            setBreadCrumbText("About Us // How We Do It");
            breadCrumb.innerText = "About Us // How We Do It";
        }
    };

    // smooth scroll and horizontal scroll
    useEffect(() => {

        if (loader) return;

        // Creating scrollSmoother
        let smoother = ScrollSmoother.create({
            content: containerRef.current,
            smooth: 2,
            effects: true,
            smoothTouch: false,
        });

        // Horizontal scroll
        const horizontalSection = document.querySelector(".animation-wrap");
        const horizontalSections = gsap.utils.toArray(".animation-wrap > *");

        let a_maxWidth = horizontalSection.offsetWidth;


        let ani = gsap.to(horizontalSection, {
            x: () => `-${a_maxWidth - window.innerWidth / 1}`,
            ease: "none",
            scrollTrigger: {
                trigger: "#about_us",
                pin: true,
                scrub: true,
                snap: 1 / (horizontalSections.length - 1),
                end: () => `+=${a_maxWidth * 40 / 100}`,
                invalidateOnRefresh: true,
            },
        });

        if (isMobileView) {
            ani.kill();
            ScrollTrigger.disable();
        }


        // Checking for hash link
        // if (window.location.hash) {
        //     gsap.to(smoother, {
        //         scrollTop: smoother.offset(window.location.hash, "top 100px"),
        //         duration: 2
        //     });
        //     // remove hash link
        //     window.history.replaceState(null, null, ' ');
        // };

    }, [loader]);

    return (
        <section id="about_us" className="about_us breadcrumb_trigger" ref={ref} data-breadcrumbtext={breadCrumbText}>
            <div ref={horizontalRef} className="animation-wrap" >
                {aboutItems.map((aboutItems, index) => (
                    <AboutItem
                        key={index}
                        index={index}
                        {...aboutItems}
                        updateItem={handleUpdateItem}
                    />
                ))}
            </div>
        </section>
    );
}



export default AboutUs;