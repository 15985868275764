import React from "react";
// import "./style.scss";
// import CustomCursorContext from "./context/CustomCursorContext";

// TODO: Hide if cursor not moved
const CustomCursor = (props) => {
  // if(props.isMobileView) return;
  // const { type } = useContext(CustomCursorContext);
  const secondaryCursor = React.useRef(null);
  const positionRef = React.useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  let addClass = function () {
    document.querySelector('.cursor').classList.add('click');
};
  let removeClass = function () {
    document.querySelector('.cursor').classList.remove('click');
};
  let moveMouse = (event) => {
    const { clientX, clientY } = event;

    const mouseX = clientX;
    const mouseY = clientY;

    positionRef.current.mouseX =
      mouseX - secondaryCursor.current.clientWidth / 2;
    positionRef.current.mouseY =
      mouseY - secondaryCursor.current.clientHeight / 2;
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", moveMouse);
    document.addEventListener('mousedown', addClass);
    document.addEventListener('mouseup', removeClass);

    return () => {
        document.removeEventListener("mousemove", moveMouse);
        document.removeEventListener('mousedown', addClass);
        document.removeEventListener('mouseup', removeClass);
    };
  }, []);

  React.useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;
      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      if (secondaryCursor && secondaryCursor.current)
        secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
    };
    followMouse();
  }, []);
  return (
    <div className="cursor" ref={secondaryCursor}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 100 100" xmlSpace="preserve">
            <path d="M65,2.2l-1.6,29.3l27-10.7l9.2,29l-28.1,7.4L89.8,80L65.5,97.8L49.9,73.1L34.5,97.8L10.2,80l18.3-22.7L0.5,49.6
                    l9.2-28.8l27,10.7L35,2.2H65z"/>
        </svg>
    </div>
  );
};

export default CustomCursor;
