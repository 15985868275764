import "./Service.css";

function Service() {

    return (
        <section className="service" id="service">
            <div className="service_content_wrap_design breadcrumb_trigger" id="design" data-breadcrumbtext="Designing" data-idname="design">
                <div className="container">
                    <div className="service_content_wrap">
                        <h1 className="heading_1">Design</h1>
                        <div className="service_content">
                            <div className="accordion_item">
                                <input type="radio" defaultChecked id="landing_page_design" 
                                name="serviceDesign" className="service_design_radio" />
                                <label className="accordion_title" htmlFor="landing_page_design">
                                    Landing Page Design 
                                    <span className="plus_icon">+</span>
                                    <span className="minus_icon">-</span>
                                </label>
                                <div className="accordion_details">
                                    <p className="paragraph_2">
                                        We create unique, professional landing pages for our clients, resulting in increased 
                                        sales and revenue and improved lead quality. 
                                        <br />
                                        <br />
                                        At Triick, we carefully craft custom landing pages with different visitors in mind, 
                                        directing your users to one clear goal while eliminating distractions along the way. 
                                        <br />
                                        <br />
                                        With our landing page design, you will receive a webpage that is not only attractively 
                                        designed but also flawlessly reflects the messaging of your brand.
                                    </p>
                                </div>
                            </div>
                            <div className="accordion_item">
                                <input type="radio" id="full_design" name="serviceDesign" className="service_design_radio" />
                                <label className="accordion_title" htmlFor="full_design">
                                    Full Website Design
                                    <span className="plus_icon">+</span>
                                    <span className="minus_icon">-</span>
                                </label>
                                <div className="accordion_details">
                                    <p className="paragraph_2">
                                        When you use Triick to design your website, you will obtain a site that has been expertly 
                                        developed to meet all your specific requirements.
                                        <br/>
                                        <br/>
                                        The design is always original, and we never use templates. Our team will provide unrestricted 
                                        revisions of the layout until you are satisfied. 
                                        <br/>
                                        <br/>
                                        Our responsive website designs allow you to provide your site visitors with the 
                                        best experience possible.
                                    </p>
                                </div>
                            </div>
                            <div className="accordion_item">
                                <input type="radio" id="mobile_design" name="serviceDesign" className="service_design_radio"/>
                                <label className="accordion_title" htmlFor="mobile_design">
                                    Mobile Application Design 
                                    <span className="plus_icon">+</span>
                                    <span className="minus_icon">-</span>
                                </label>
                                <div className="accordion_details">
                                    <p className="paragraph_2">
                                        We develop interactive user interfaces, user experiences, and visual designs that will 
                                        boost your customer engagement. 
                                        <br/>
                                        <br/>
                                        With jaw-dropping designs that improve the overall experience for the app's end users, 
                                        we always stay up-to-date with trends in the mobile app world and adhere strictly to 
                                        the industry's best practices to align with your objectives and produce a positive effect.
                                    </p>
                                </div>
                            </div>
                            <div className="accordion_item">
                                <input type="radio" id="graphic_design" name="serviceDesign" className="service_design_radio"/>
                                <label className="accordion_title" htmlFor="graphic_design">
                                    Graphic Design
                                    <span className="plus_icon">+</span>
                                    <span className="minus_icon">-</span>
                                </label>
                                <div className="accordion_details">
                                    <p className="paragraph_2">
                                        At Triick, our graphic designers will collaborate with you to produce energising visual 
                                        designs of the highest possible quality based on your specific requirements, allowing 
                                        you to communicate effectively with the people you want to reach.
                                        <br/>
                                        <br/>
                                        Whether you need a rebrand or need to produce promotional material, you can rely on 
                                        our in-house expertise to create designs that will impress.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_content_wrap_develop breadcrumb_trigger" id="develop" data-breadcrumbtext="Development" data-idname="develop">
                <div className="container">
                    <div className="service_content_wrap">
                        <h1 className="heading_1">Develop</h1>
                        <div className="service_content">
                            <div className="service_dev">
                                <div className="service_dev_item">
                                    <h3 className="heading_3">HTML Templates</h3>
                                    <p className="paragraph_3">
                                        We develop HTML templates that include built-in images, text content, and support files 
                                        for font styles and Javascript. These templates feature blank spaces where you can 
                                        insert your information, text, and images before uploading them to a hosting web account.
                                    </p>
                                </div>
                                <div className="service_dev_item">
                                    <h3 className="heading_3">App Creation</h3>
                                    <p className="paragraph_3">
                                    Our app design and development have no limits. After receiving the brief from you, our top 
                                    app developers will first create the application's blueprint. Then we will follow a step-by-step 
                                    strategy for the application's design and development, bringing your application to the highest 
                                    level without any bugs, threats, or problems.
                                    </p>
                                </div>
                                <div className="service_dev_item">
                                    <h3 className="heading_3">Dynamic Custom Website </h3>
                                    <p className="paragraph_3">
                                    Because of how quickly technology, fashion, and customer preference can shift, website owners must 
                                    keep their sites as current as possible. Having a dynamic website makes all of this incredibly 
                                    simple to achieve. The flexibility to make changes to it whenever necessary ensures guests will 
                                    never become bored.
                                    </p>
                                </div>
                                <div className="service_dev_item">
                                    <h3 className="heading_3">Web Applications</h3>
                                    <p className="paragraph_3">
                                    We develop and deploy mission-critical web applications that are scalable according to your 
                                    company's needs. Construct, deploy, and manage highly effective web applications; kickstart 
                                    your app's creation; boost your developers' productivity; expedite the delivery of updates; 
                                    realise global scale on demand.
                                    </p>
                                </div>
                                <div className="service_dev_item">
                                    <h3 className="heading_3">WordPress Development</h3>
                                    <p className="paragraph_3">
                                    We offer WordPress Development Solutions of the highest quality to create interactive digital 
                                    experiences. Our knowledgeable team of WordPress Developers collaborates with the most recent 
                                    industry developments and established standards of practice.
                                    </p>
                                </div>
                                <div className="service_dev_item">
                                    <h3 className="heading_3">MERN Stack App</h3>
                                    <p className="paragraph_3">
                                    Our MERN Stack development services can develop web apps quickly. MERN Stack will speed up your 
                                    backend and give you a competitive edge. Because it combines MongoDB, Express, React, and Node, 
                                    it's an excellent choice for developing reliable web apps for companies of all sizes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Service;