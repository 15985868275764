import './Nav.css';
import logo from '../../images/logo.svg';
import { useEffect, useRef, useState } from 'react';

import gsap from "gsap";

function Nav(props) {
    const invertDom = useRef();
    const [isMenuActive, setIsMenuActive] = useState(false);
    const domainName = window.location.origin;
    const domainPathName = window.location.pathname;

    function invert() {
        let trigger = invertDom.current.checked;
        props.handleInvert(trigger);
    }

    function scrollToSection(e, target) {
        if (domainPathName === "/") {
            e.preventDefault();
            gsap.to(window, { delay: (props.isMobileView) ? 1 : 0, duration: 1, scrollTo: { y: target } });
            setIsMenuActive(false);
        }
    };

    function scrollToAbout(e) {
        if (domainPathName === "/") {
            e.preventDefault();
            gsap.to(window, { delay: (props.isMobileView) ? 1 : 0, duration: 1, scrollTo: { y: "#home", offsetY: -window.innerHeight } });
            setIsMenuActive(false);
        }
    };

    function scrollIconClick() {
        gsap.to(window, { delay: (props.isMobileView && isMenuActive) ? 1 : 0, duration: 1, scrollTo: { y: "#about_us" } });
        setIsMenuActive(false);
    };

    const menuToggle = () => {
        setIsMenuActive(!isMenuActive);
        // window.location.reload(false);
    };


    useEffect(() => {

        if (!props.nav_active) {
            // Nav toggle
            window.onscroll = function (e) {

                if (
                    document.body.scrollTop > 50 ||
                    document.documentElement.scrollTop > 50
                ) {
                    document.querySelector(".fixed_content").classList.add("nav_active");
                } else {
                    document.querySelector(".fixed_content").classList.remove("nav_active");
                }
            };
        } else {
            document.querySelector(".fixed_content").classList.add("nav_active");
        }

    }, []);

    useEffect(() => {
        if (!props.isMobileView) return;
        if (isMenuActive) {
            gsap.to(".menu_mobile_wrap", {
                translateX: "0%",
                opacity: 1,
                duration: 1.1,
                ease: "expo.out",
            });
            gsap.fromTo(
                ".menu_mobile li",
                { x: 100, opacity: 0 },
                {
                    x: 0,
                    delay: 0.3,
                    duration: 1.1,
                    ease: "expo.out",
                    stagger: 0.05,
                    opacity: 1,
                }
            );

        } else {
            gsap.fromTo(
                ".menu_mobile li",
                { x: 0, opacity: 1 },
                {
                    x: 300,
                    duration: 1.3,
                    ease: "expo.out",
                    stagger: 0.05,
                    opacity: 0,
                }
            );
            gsap.to(".menu_mobile_wrap", {
                opacity: 0,
                delay: .6,
                duration: 1.3,
                ease: "expo.out",
            });
            gsap.to(".menu_mobile_wrap", {
                translateX: "105%",
                delay: 2,
                ease: "expo.out",
            });
        }
    }, [isMenuActive, props.isMobileView]);

    return (
        <div className="fixed_content mobile_menu_active">
            <div className="global_settings">
                <div className="top_settings">
                    <div className="container">
                        {
                            ((props.isMobileView) ? (
                                <div className="menu_bar">
                                    <button className={`menu_bar_icon ` + (isMenuActive ? 'active' : '')} onClick={menuToggle} >
                                        <svg viewBox="0 0 64 48">
                                            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
                                            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
                                            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
                                        </svg>
                                    </button>
                                </div>
                            ) : (
                                <a href={`${domainName}#contact`} className="contactButton_top " onClick={(e) => scrollToSection(e, "#contact")}> LET’S TALK</a>
                            )
                            )
                        }
                        <div className="invert_wrap">
                            <label htmlFor="invert">
                                <input type="checkbox" id="invert" className="invert_check" ref={invertDom} onClick={invert} />
                                <span className="invert_icons">
                                    <span className="night">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.53 28.95">
                                            <g>
                                                <path d="M10.11,14.48c0-6.22,3.92-11.52,9.42-13.57-1.57-.59-3.28-.91-5.06-.91C6.48,0,0,6.48,0,14.48s6.48,14.48,14.48,14.48c1.78,0,3.48-.32,5.06-.91-5.5-2.05-9.42-7.35-9.42-13.57Z" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="day">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.09 30.09">
                                            <g>
                                                <circle cx="15.05" cy="15.05" r="7.85" transform="translate(-2.21 27.5) rotate(-80.83)" />
                                                <path d="M15.05,6.3c-.27,0-.49-.22-.49-.49V.49c0-.27,.22-.49,.49-.49s.49,.22,.49,.49V5.81c0,.27-.22,.49-.49,.49Z" />
                                                <path d="M19.66,7.54c-.08,0-.17-.02-.25-.07-.24-.14-.32-.44-.18-.67l2.66-4.61c.14-.24,.44-.32,.67-.18,.24,.14,.32,.44,.18,.67l-2.66,4.61c-.09,.16-.26,.25-.43,.25Z" />
                                                <path d="M23.05,10.92c-.17,0-.34-.09-.43-.25-.14-.24-.06-.54,.18-.67l4.6-2.66c.24-.14,.54-.06,.67,.18,.14,.24,.06,.54-.18,.67l-4.6,2.66c-.08,.04-.16,.07-.25,.07Z" />
                                                <path d="M24.28,15.54c-.27,0-.49-.22-.49-.49s.22-.49,.49-.49h5.32c.27,0,.49,.22,.49,.49s-.22,.49-.49,.49h-5.32Z" />
                                                <path d="M27.65,22.82c-.08,0-.17-.02-.25-.07l-4.61-2.66c-.24-.14-.32-.44-.18-.67,.14-.24,.44-.32,.67-.18l4.61,2.66c.24,.14,.32,.44,.18,.67-.09,.16-.26,.25-.43,.25Z" />
                                                <path d="M22.33,28.14c-.17,0-.34-.09-.43-.25l-2.66-4.6c-.14-.24-.06-.54,.18-.67,.24-.14,.54-.06,.67,.18l2.66,4.6c.14,.24,.06,.54-.18,.67-.08,.05-.16,.07-.25,.07Z" />
                                                <path d="M15.05,30.09c-.27,0-.49-.22-.49-.49v-5.32c0-.27,.22-.49,.49-.49s.49,.22,.49,.49v5.32c0,.27-.22,.49-.49,.49Z" />
                                                <path d="M7.77,28.15c-.08,0-.17-.02-.25-.07-.24-.14-.32-.44-.18-.67l2.66-4.61c.14-.24,.44-.32,.67-.18,.24,.14,.32,.44,.18,.67l-2.66,4.61c-.09,.16-.26,.25-.43,.25Z" />
                                                <path d="M2.45,22.82c-.17,0-.34-.09-.43-.25-.14-.24-.06-.54,.18-.67l4.6-2.66c.23-.14,.54-.06,.67,.18,.14,.24,.06,.54-.18,.67l-4.6,2.66c-.08,.05-.16,.07-.25,.07Z" />
                                                <path d="M.49,15.54c-.27,0-.49-.22-.49-.49s.22-.49,.49-.49H5.81c.27,0,.49,.22,.49,.49s-.22,.49-.49,.49H.49Z" />
                                                <path d="M7.05,10.92c-.08,0-.17-.02-.25-.07l-4.61-2.66c-.24-.14-.32-.44-.18-.67,.14-.24,.44-.32,.67-.18l4.61,2.66c.24,.14,.32,.44,.18,.67-.09,.16-.26,.25-.43,.25Z" />
                                                <path d="M10.43,7.54c-.17,0-.34-.09-.43-.25L7.34,2.69c-.14-.24-.06-.54,.18-.67,.24-.14,.54-.06,.67,.18l2.66,4.6c.14,.24,.06,.54-.18,.67-.08,.05-.16,.07-.25,.07Z" />
                                            </g>
                                        </svg>
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="bottom_settings">
                    <div className="container ">
                        <div className="breadcrumb">
                            <span className="breadcrumb_item">TRIICK //</span>
                            <span className="breadcrumb_item active_breadcrumb">HOME</span>
                        </div>
                    </div>
                </div>

                <div className="scroll_down" onClick={scrollIconClick}>
                    <div className="scroll"></div>
                </div>
            </div>
            {
                ((props.isMobileView) ? (
                    <nav className="nav_mobile ">
                        <a href={`${domainName}#home`} className="logo" onClick={(e) => scrollToSection(e, "#home")}>
                            <img className="logo-img" src={logo} alt="TRICK" />
                        </a>
                        <div className="menu_mobile_wrap">
                            <ul className="menu_mobile">
                                <li><a href={`${domainName}#home`} className="menu_item" onClick={(e) => scrollToSection(e, "#home")}>Home</a></li>
                                <li><a href={`${domainName}#about_us`} className="menu_item" onClick={(e) => scrollToAbout(e)}>About Us</a></li>
                                <li><a href={`${domainName}#service`} className="menu_item" onClick={(e) => scrollToSection(e, "#service")}>Services</a></li>
                                <li><a href={`${domainName}#work`} className="menu_item" onClick={(e) => scrollToSection(e, "#work")}>Our Work</a></li>
                                <li><a href={`${domainName}#contact`} className="menu_item " onClick={(e) => scrollToSection(e, "#contact")}>Let's Talk</a></li>
                            </ul>
                        </div>
                    </nav>
                ) : (
                    <nav className="nav ">
                        <div className="container">
                            <a href={`${domainName}#home`} className="logo" onClick={(e) => scrollToSection(e, "#home")}>
                                <img className="logo-img" src={logo} alt="TRICK" />
                            </a>
                            <ul className="menu">
                                <li><a href={`${domainName}#home`} className="menu_item" onClick={(e) => scrollToSection(e, "#home")}>Home</a></li>
                                <li><a href={`${domainName}#about_us`} className="menu_item" onClick={(e) => scrollToAbout(e)}>About Us</a></li>
                                <li><a href={`${domainName}#service`} className="menu_item" onClick={(e) => scrollToSection(e, "#service")}>Services</a></li>
                                <li><a href={`${domainName}#work`} className="menu_item" onClick={(e) => scrollToSection(e, "#work")}>Our Work</a></li>
                                <li><a href={`${domainName}#contact`} className="menu_item lets_talk_menu" onClick={(e) => scrollToSection(e, "#contact")}>LET’S TALK</a></li>
                            </ul>
                        </div>
                    </nav>
                )
                )
            }
        </div>
    );
}

export default Nav;