import "./Header.css";
import { useEffect } from "react";


import gsap from "gsap";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(SplitText);

function Header() {

    useEffect(() => {
        const headingWords = new SplitText(".hero_heading", { type: "words,lines" });
        const subheadingWords = new SplitText(".hero_paragraph", { type: "words,chars" });

        gsap.from(headingWords.words, {
            y: 40,
            opacity: 0,
            stagger: 0.3,
            delay: 3.2,
            ease: "circ.out",
        });
        gsap.from(subheadingWords.chars, {
            y: 40,
            stagger: 0.02,
            duration: .5,
            opacity: 0,
            delay: 4,
            ease: "power1.inOut",
        });
    }, []);

    return (
        <header className="header breadcrumb_trigger" id="home" data-breadcrumbtext="Home">
            <div className="hero">
                <div className="container">
                    <h1 className="hero_heading">Design. Develop. Difference.</h1>
                    <p className="hero_paragraph">Our digital products and services make your business grow</p>
                </div>
            </div>
        </header>
    );
}

export default Header;