import "./Work.css";
import ww_thubnail from "../../images/ww_thubnail.png"
function Work(props) {
    return (
        <section id="work" className="work breadcrumb_trigger" data-breadcrumbtext="Work">
            <div className="container">
                <div className="work_content_wrap">
                    <h1 className="heading_1">Our Work</h1>
                    <div className="work_container">
                        <div className="work_single">
                            <a href="/WWApp" className="section_link">
                                <img src={ww_thubnail} alt="ww_thubnail" className="ww_thubnail no_invert" />
                                <h2 className="work_single_title">Window Warehouse App</h2>
                                {/* work_single_paragraph */}
                                <p className="work_single_paragraph">
                                    The Window Warehouse app is designed to help streamline customer service and is the ultimate resource for all your window and door needs.
                                </p>
                                <p className="work_single_link">Read more...</p>
                            </a>
                        </div>
                        <div className="work_dubble">
                            <div className="work_loading">
                                <p className="work_loading_text">MORE WORK IN PROGRESS</p>
                                <div className="workLoading"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Work;