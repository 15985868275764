function SendMail(data) {
    return fetch('https://api.triick.co.uk/api/sendmail', {
        method:"POST",
        headers:{
            "Accept": "application/json",
            "Content-type" : "application/json"
        },
        body: JSON.stringify(data)
    }).then(response=>{
        return  response.ok;
        // console.log("response",response);
        // return response.json();
    }).catch(err=>console.log(err))
}

export default SendMail;